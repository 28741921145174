import moment from 'moment';

import localization from '../localization/i18n';
import { getLocale, getToken } from '../services/storageService/GlobalData';
import getLanguageKey from '../services/languageService/index';

import {
  DATE_FORMATS,
  NOTIFICATION_METADATA,
  NOTIFICATION_UPDATE_EVENTS,
} from '../constants/AppConstants';
import DashboardHelper from './DashboardHelper';
import { baseUrl } from '../services/api';
import APIConstants from '../constants/APIConstants';

class NotificationHelper {
  static getTitleFromNotification(notification) {
    const localeKey = getLanguageKey();
    if (notification.feedlot_activity) {
      let activity = notification.feedlot_activity;
      return activity.locale && activity.locale[localeKey]
        ? activity.locale[localeKey].title
        : '';
    } else if (
      notification.feedlot_document &&
      notification.feedlot_document.document_locale &&
      notification.feedlot_document.document_locale[localeKey].title
    ) {
      return notification.feedlot_document.document_locale[localeKey].title;
    } else if (notification.audit_assignment) {
      return notification.audit_assignment.audit.audit_number;
    }
    return '';
  }

  static getNotificationActivityHeading(notification) {
    const acitivity_number = notification.feedlot_activity.activity_number;
    const title = NotificationHelper.getTitleFromNotification(notification);
    const heading = title ? `${acitivity_number} - ${title}` : acitivity_number;
    return heading;
  }

  static getNotificationTrainingHeading(notification) {
    const document_number_without_prefix =
      notification.feedlot_document.document_number.replace(/^m-/, '');
    const title = NotificationHelper.getTitleFromNotification(notification);
    const heading = `${document_number_without_prefix} - ${title}`;
    return heading;
  }

  static getNotificationTimesAgo(notification) {
    const notification_create_datetime = notification.create_datetime;
    const notification_times_ago = DashboardHelper.getTimesAgo(
      notification_create_datetime,
    );
    return notification_times_ago;
  }

  static getNotificationActivityInstanceValue(notification) {
    const activity = notification.feedlot_activity;
    let instance_value = '';
    if (activity.instances && activity.instances[0]) {
      instance_value = activity.instances[0].instance_value;
    } else {
      instance_value = activity.locale[getLanguageKey()].instance_name;
    }
    return instance_value;
  }

  static getNotificationActivityCommonData(notification) {
    const heading =
      NotificationHelper.getNotificationActivityHeading(notification);
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const instance_value =
      NotificationHelper.getNotificationActivityInstanceValue(notification);
    const isActivityInstanceBased =
      notification.feedlot_activity.instance_based;
    return {
      heading,
      notification_times_ago,
      instance_value,
      isActivityInstanceBased,
    };
  }

  static getReminderData(notification) {
    const { heading, notification_times_ago } =
      NotificationHelper.getNotificationActivityCommonData(notification);

    const reminder_date = moment(
      notification.feedlot_activity.reminder_date,
    ).format(DATE_FORMATS.MM_DD_YYYY);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_REMINDER
    } - ${reminder_date} - ${notification_times_ago}`;

    return {
      heading,
      subHeading,
      iconName: NOTIFICATION_METADATA.reminderIconName,
      iconColor: NOTIFICATION_METADATA.reminderIconColor,
      titleColor: NOTIFICATION_METADATA.reminderTitleColor,
    };
  }

  static getDueTodayData(notification) {
    const {
      heading,
      notification_times_ago,
      instance_value,
      isActivityInstanceBased,
    } = NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = isActivityInstanceBased
      ? `${
          localization[getLocale()].NOTIFICATION_ACTIVITY_DUE_TODAY
        } - ${instance_value} - ${notification_times_ago}`
      : `${
          localization[getLocale()].NOTIFICATION_ACTIVITY_DUE_TODAY
        } - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.dueTodayIconColor,
      iconName: NOTIFICATION_METADATA.dueTodayIconName,
      titleColor: NOTIFICATION_METADATA.dueTodayTitleColor,
    };
  }

  static getActivityMissedData(notification) {
    const {
      heading,
      notification_times_ago,
      instance_value,
      isActivityInstanceBased,
    } = NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = isActivityInstanceBased
      ? `${
          localization[getLocale()].NOTIFICATION_ACTIVITY_MISSED
        } - ${instance_value} - ${notification_times_ago}`
      : `${
          localization[getLocale()].NOTIFICATION_ACTIVITY_MISSED
        } - ${notification_times_ago}`;
    return { heading, subHeading };
  }

  static getSubmissionVoilation(notification) {
    const {
      heading,
      notification_times_ago,
      instance_value,
      isActivityInstanceBased,
    } = NotificationHelper.getNotificationActivityCommonData(notification);

    const subHeading = isActivityInstanceBased
      ? `${
          localization[getLocale()].NOTIFICATION_SUBMISSION_VOILATION
        } - ${instance_value} - ${notification_times_ago}`
      : `${
          localization[getLocale()].NOTIFICATION_SUBMISSION_VOILATION
        } - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.submissionVoilationIconColor,
      titleColor: NOTIFICATION_METADATA.submissionVoilationTitleColor,
      iconName: NOTIFICATION_METADATA.submissionVoilationIconName,
    };
  }

  static getDocumentsReadyData(notification) {
    const { heading, notification_times_ago } =
      NotificationHelper.getNotificationActivityCommonData(notification);

    const subHeading = `${
      localization[getLocale()].NOTIFICATION_DOCUMENT_READY
    } - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.documentsReadyIconColor,
      titleColor: NOTIFICATION_METADATA.documentsReadyTitleColor,
      iconName: NOTIFICATION_METADATA.documentsReadyIconName,
    };
  }

  static getDocumentReadyPdfSource(notification) {
    const pdfSource = {
      uri: `${baseUrl}${APIConstants.POST_ACTIVITY_PRINT}/${notification.feedlot_report.file}`,
      headers: { token: getToken() },
    };
    return pdfSource;
  }

  static getTrainingDueTodayData(notification) {
    const heading =
      NotificationHelper.getNotificationTrainingHeading(notification);
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_TRAINING_DUE_TODAY
    } - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.trainingDueTodayIconColor,
      iconName: NOTIFICATION_METADATA.trainingDueTodayIconName,
      titleColor: NOTIFICATION_METADATA.trainingDueTodayTitleColor,
    };
  }

  static getTrainingViewedData(notification) {
    const heading =
      NotificationHelper.getNotificationTrainingHeading(notification);
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const user_name = notification.user.user_name;
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_TRAINING_VIEWED_BY
    } ${user_name} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.trainingViewedIconColor,
      iconName: NOTIFICATION_METADATA.trainingViewedIconName,
      titleColor: NOTIFICATION_METADATA.trainingViewedTitleColor,
    };
  }

  static getTrainingMissedData(notification) {
    const heading =
      NotificationHelper.getNotificationTrainingHeading(notification);
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const user_name = notification.user.user_name;
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_TRAINING_MISSED
    } ${user_name} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.trainingViewedIconColor,
      iconName: NOTIFICATION_METADATA.trainingViewedIconName,
      titleColor: NOTIFICATION_METADATA.trainingViewedTitleColor,
    };
  }

  static getActivityInstanceAssignedData(notification) {
    const { heading, notification_times_ago, instance_value } =
      NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_ACTIVITY_INSTANCE_ASSIGNED
    } ${instance_value} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.activityInstanceAssignedIconColor,
      titleColor: NOTIFICATION_METADATA.activityInstanceAssignedTitleColor,
      iconName: NOTIFICATION_METADATA.activityInstanceAssignedIconName,
    };
  }

  static getActivityLog8FilledData(notification) {
    const { heading, notification_times_ago, instance_value } =
      NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_ACTIVITY_LOG8_FILLED
    } ${instance_value} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.activityLog8FilledIconColor,
      titleColor: NOTIFICATION_METADATA.activityLog8FilledTitleColor,
      iconName: NOTIFICATION_METADATA.activityLog8FilledIconName,
    };
  }

  static getActivityLog8CompletedData(notification) {
    const { heading, notification_times_ago, instance_value } =
      NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_ACTIVITY_LOG8_COMPLETED
    } ${instance_value} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.activityLog8CompletedIconColor,
      titleColor: NOTIFICATION_METADATA.activityLog8CompletedTitleColor,
      iconName: NOTIFICATION_METADATA.activityLog8CompletedIconName,
    };
  }

  static getActivityRevisionUpdate(notification) {
    const { heading, notification_times_ago } =
      NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_REVISION_AVAILABLE
    } - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.activityRevisionUpdatedIconColor,
      iconName: NOTIFICATION_METADATA.activityRevisionUpdatedIconName,
      titleColor: NOTIFICATION_METADATA.activityRevisionUpdatedTitleColor,
    };
  }

  static getDocumentRevisionUpdateData(notification) {
    const document_number = notification.feedlot_document.document_number;
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const heading = `${document_number} - ${NotificationHelper.getTitleFromNotification(
      notification,
    )}`;
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_REVISION_AVAILABLE
    } - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.activityRevisionUpdatedIconColor,
      iconName: NOTIFICATION_METADATA.activityRevisionUpdatedIconName,
      titleColor: NOTIFICATION_METADATA.activityRevisionUpdatedTitleColor,
    };
  }

  static getActivityAssignedData(notification) {
    const { heading, notification_times_ago, instance_value } =
      NotificationHelper.getNotificationActivityCommonData(notification);
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_ACTIVITY_ASSIGNED
    } ${instance_value} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.activityInstanceAssignedIconColor,
      titleColor: NOTIFICATION_METADATA.activityInstanceAssignedTitleColor,
      iconName: NOTIFICATION_METADATA.activityInstanceAssignedIconName,
    };
  }

  static getAuditSubmittedData(notification) {
    const heading = `${NotificationHelper.getTitleFromNotification(
      notification,
    )}`;
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const submitted_by =
      notification.audit_assignment.auditor.profile.first_name +
      ' ' +
      notification.audit_assignment.auditor.profile.last_name;
    const subHeading = `${
      localization[getLocale()].NOTIFICATION_AUDIT_SUBMITTED
    } ${submitted_by} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.auditSubmittedIconColor,
      titleColor: NOTIFICATION_METADATA.auditSubmittedTitleColor,
      iconName: NOTIFICATION_METADATA.auditSubmittedIconName,
    };
  }

  static getNewCommentAddedData(notification) {
    const heading = `${NotificationHelper.getTitleFromNotification(
      notification,
    )}`;
    const notification_times_ago =
      NotificationHelper.getNotificationTimesAgo(notification);
    const auditor_profile = notification.audit_assignment.auditor.profile;
    const name =
      notification.creator_name ??
      `${auditor_profile.first_name} ${auditor_profile.last_name}`;
    const subHeading = `${
      localization[getLocale()].NEW_COMMENT_ADDED
    } ${name} - ${notification_times_ago}`;
    return {
      heading,
      subHeading,
      iconColor: NOTIFICATION_METADATA.commentAddedIconColor,
      titleColor: NOTIFICATION_METADATA.commentAddedTitleColor,
      iconName: NOTIFICATION_METADATA.commentAddedIconName,
    };
  }

  static getNotificationUpdateData(notification) {
    const event = notification.event;
    const heading =
      NotificationHelper.getNotificationActivityHeading(notification);
    const userFullName =
      notification.user && notification.user.profile
        ? notification.user.profile.first_name +
          ' ' +
          notification.user.profile.last_name
        : '';
    if (event === NOTIFICATION_UPDATE_EVENTS.ACTIVITY_SUBMISSION_ADDED) {
      const subHeading = `${
        localization[getLocale()].UPDATE_SUBMITTED_BY
      } ${userFullName}`;
      return {
        heading,
        subHeading,
      };
    } else if (event === NOTIFICATION_UPDATE_EVENTS.ACTIVITY_ASSIGNED_UPDATE) {
      const subHeading = `${
        localization[getLocale()].UPDATE_ACTIVITY_ASSIGNED
      } ${userFullName}`;
      return {
        heading,
        subHeading,
      };
    } else if (
      event === NOTIFICATION_UPDATE_EVENTS.ACTIVITY_SUBMISSION_REMOVED
    ) {
      const subHeading = `${
        localization[getLocale()].UPDATE_SUBMISSION_REMOVED
      } ${userFullName}`;
      return {
        heading,
        subHeading,
        isRemovedUpdate: true,
      };
    } else if (
      event === NOTIFICATION_UPDATE_EVENTS.ACTIVITY_INSTANCE_ASSIGNED_UPDATE
    ) {
      const instance_value =
        NotificationHelper.getNotificationActivityInstanceValue(notification);
      const subHeading = `${instance_value} ${
        localization[getLocale()].UPDATE_ACTIVITY_ASSIGNED
      } ${userFullName}`;
      return {
        heading,
        subHeading,
      };
    } else {
      return {
        heading,
        subHeading: event,
      };
    }
  }
}

export default NotificationHelper;
